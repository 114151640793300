.main {
    padding-bottom: 68px;
    background: #edfff7ad;
  }
  
  .firstSectionWrapper {
    text-align: center;
    color: #1f3a45;
    position: relative;
    padding-top: 44px;
    margin-right: 12px;
    margin-left: 12px;
  
    .mug {
      opacity: 0.08;
      position: absolute;
      right: 5%;
      top: 265px;
      width: 183px;
      height: auto;
      transform: rotate(20deg);
    }
  
    .grains {
      opacity: 0.08;
      position: absolute;
      left: 5%;
      top: -15px;
      width: 153px;
      height: auto;
      transform: rotate(-20deg);
    }
  }
  
  .firstSectionInner {
    display: flex;
    width: 1280px;
    max-width: 100%;
    margin: auto;
  }
  
  .subtitle {
    font-size: 22px;
    line-height: 32px;
    width: 650px;
    max-width: 100%;
    text-align: center;
    margin: auto;
    margin-top: 24px;
  }
  
  .actions {
    max-width: 100%;
    width: 370px;
    justify-content: center;
    margin: auto;
    display: flex;
    margin-top: 44px;
  }
  
  .mainTitle {
    text-align: center;
    font-size: 46px;
    line-height: 58px;
    width: 760px;
    max-width: 100%;
    margin: auto;
  }
  
  .icon {
    font-size: 54px;
    margin-bottom: 12px
  }
  
  .landingQuote {
    text-align: center;
    font-size: 36px;
    padding-top: 44px;
    padding-bottom: 44px;
    width: 100%;
    // background: #f1f1a0;``
    background: linear-gradient(312deg, #b8eed7, #70e5b3);
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .vignettes {
    display: flex;
    flex-wrap: wrap;
    margin-top: 28px;
  }
  
  .vignetteDesc {
    font-size: 20px;
    font-weight: normal;
    margin-top: 20px;
    color: #626262;
  
  }
  
  .video {
    display: flex;
    justify-content: center;
    margin-bottom: 28px;
  }
  
  .vignetteLeft {
    flex-grow: 1;
  }
  
  .vignetteWrapper {
    display: flex;
  }
  
  .picture {
    margin-bottom: -85px;
    position: relative;
    display: flex;
    align-items: flex-end;
    padding: 44px 32px;
    box-shadow: 0px 0px 14px 0px #0000000f;
    border-radius: 34px;
  }
  
  .tag {
    border: none;
    padding: 6px 22px;
    font-weight: bold;
    background-color: #daffd0;
    color: #28840f;
  }
  
  .tagWrapper {
    display: flex;
    justify-content: center;
  }
  
  .hint {
    color: #626262;
    margin-top: 6px;
  }
  
  .featureWrapper {
    display: flex;
    justify-content: center;
    margin-top: 84px;
  }
  
  .benefitsWrapper {
    display: flex;
    justify-content: center;
    margin-top: 84px;
  }
  
  .quoteWrapper {
    margin-top: 24px;
    margin-bottom: 84px;
  }
  
  .installWrapper {
    margin-top: 124px;
  }
  
  .picturesProductInner {
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  
    .picturesProductLeft {
      margin-right: 10px;
      width: 500px;
      height: auto;
    }
  
    .picturesProductRight {
      width: 600px;
      height: auto;
      margin-left: -200px;
    }
  }
  
  .ratedWrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 8px;
  }
  
  /* Mobile */
  @media (max-width: 1070px) {
    .firstSectionWrapper {
      padding-top: 24px;
  
      .mug {
        opacity: 0.04;
        width: 103px;
      }
  
      .grains {
        opacity: 0.04;
        width: 103px;
      }
    }
  
    .mainTitle {
      text-align: center;
      font-size: 48px;
      line-height: 58px;
      width: 800px;
    }
  
  }
  
  @media (max-width: 900px) {
    .picturesProductInner {
      height: auto;
      border: none;
  
      .picturesProductLeft {
        display: flex;
        justify-content: center;
        width: 400px;
        max-width: 100%;
      }
  
      .picturesProductRight {
        display: flex;
        justify-content: center;
        width: 500px;
        max-width: 100%;
        margin-left: -260px;
      }
    }
  
    .vignetteLeft {
      flex-basis: 100%;
    }
  
    .vignetteWrapper {
      flex-wrap: wrap;
    }
  
    .mainTitle {
      font-size: 38px;
      line-height: 42px;
    }
  
    .picture {
      margin-bottom: -85px;
      position: relative;
      display: flex;
      align-items: center;
      padding: 44px 32px;
      box-shadow: 0px 0px 14px 0px #0000000f;
      border-radius: 34px;
      margin-top: 24px;
  
      .pictureVignette {
        max-width: 100%;
        height: auto;
      }
    }
  }
  
  @media (max-width: 700px) {
    .actions {
      flex-wrap: wrap;
    }
  
  }
    @media (max-width: 650px) {
    .picturesProductInner {
      flex-wrap: wrap;
  
      .picturesProductLeft {
        display: flex;
        justify-content: center;
        width: 400px;
        max-width: 100%;
      }
  
      .picturesProductRight {
        width: 500px;
        max-width: 100%;
        margin-left: 0px;
        margin-top: -200px;
      }
    }
  }
  
  @media (max-width: 570px) {
    .mainTitle {
      text-align: center;
      font-size: 38px;
      line-height: 48px;
      width: 460px;
    }
  
    .picture {
      padding: 24px 22px;
    }
  
    .howItWorks {
      margin-top: 50px;
    }
  
  }
  
  @media (max-width: 420px) {
    .firstSectionWrapper {
  
      .mainTitle {
        font-size: 34px;
        line-height: 44px;
      }
    }
  }